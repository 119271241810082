import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons"

export const Aside = () => {
  return (
    <aside className="l-sidebar float_right">
      <div className="download">
        <a
          className="download__link"
          href="https://hub.park-lot.com/document-download"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="download__img-wrapper">
            <StaticImage
              layout="constrained"
              placeholder="tracedSVG"
              alt=""
              src="../../images/blog/aside-download.png"
              maxwidth={300}
            ></StaticImage>
          </div>
          <p className="download__text">
            3分で分かるPARKLoT資料
            <br />
            をダウンロード
          </p>
          <div className="download__icon">
            <FontAwesomeIcon icon={faArrowCircleDown} />
          </div>
        </a>
      </div>
      <div className="contact">
        <p className="contact__text">
          オンラインでの
          <br />
          ご相談を
          <br />
          無料で受付中！
        </p>
        <a
          className="ga-contact-aside contact__link btn-white"
          href="https://hub.park-lot.com/free-consulting"
          target="_blank"
          rel="noreferrer noopener"
        >
          今すぐ無料相談を予約する
        </a>
      </div>
    </aside>
  )
}
