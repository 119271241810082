import React from "react"
import { Link } from "gatsby"

export const Breadcrumb = props => {
  if (props.items) {
    return (
      <div className="breadcrumb">
        <ol className="breadcrumb__list l-container">
          <li className="breadcrumb__item">
            <Link to={`/`} className="breadcrumb__link">
              ホーム
            </Link>
          </li>
          {props.items.map((item, index) =>
            props.items.length - 1 === index ? (
              <li key={index} className="breadcrumb__item">
                <span>{item.title}</span>
              </li>
            ) : (
              <li key={index} className="breadcrumb__item">
                <Link to={item.link} className="breadcrumb__link">
                  {item.title}
                </Link>
              </li>
            )
          )}
        </ol>
      </div>
    )
  }

  return (
    <div className="breadcrumb">
      <ol className="breadcrumb__list l-container">
        <li className="breadcrumb__item">
          <Link to={`/`} className="breadcrumb__link">
            ホーム
          </Link>
        </li>
        <li className="breadcrumb__item">{props.title}</li>
      </ol>
    </div>
  )
}
