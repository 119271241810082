import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const IsItPossibleToCreateAScratchCampaign = ({ location }) => {
  const title = "インスタントウィン結果のデザインを変更できる？"
  const list = [
    { title: "よくある質問", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="当選結果の演出をスロットやスクラッチなどゲーム性が高いものにすることで、より訴求効果が高いキャンペーンを実施可能です。通常のキャンペーンよりも参加者に強烈にブランドを認知させることができます。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="よくある質問" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              当選結果を表示する画面の演出をカスタマイズすることは可能ですか？
            </h1>
            <p className="qa-page__answer">
              はい、オプションとなりますが弊社で対応可能です。
              <br />
            </p>
            <p className="qa-page__text">
              ブランドのイメージに合わせた画像やキャラクターを使用した演出を表示し、参加者に商品名やサービス名を印象付けることが可能となります。
            </p>
            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">
                  演出をカスタマイズした場合のキャンペーン参加〜商品受け取りまでの流れ
                </h2>

                <div className="qa-page__text">
                  <ol className="qa-page__ol">
                    <li>
                      リツイートでキャンペーンに参加(その他の参加方法でも可能です)
                    </li>
                    <li>ダイレクトメッセージで結果通知</li>
                    <li>
                      ダイレクトメッセージ記載してあるURLからキャンペーンサイトへ遷移
                    </li>
                    <li>
                      サイト内で演出を表示する(動画、アニメーション、ゲーム、スライド
                      etc ...)
                    </li>
                    <li>演出完了後、当選・落選の結果が表示される</li>
                    <li>当たりの場合、景品受け取り</li>
                  </ol>
                </div>

                <h3 className="qa-page__h3">
                  演出部分のカスタマイズの設計・提案などもお気軽にご相談ください。
                </h3>

                <p className="qa-page__text">
                  結果表示後、アンケートに答えるとダブルチャンスに参加させるなどのキャンペーン設計も可能です。
                </p>

                <p className="qa-page__text">
                  演出のカスタマイズに関してはPARKLoTのオプション機能になりますので、詳細についてはお問い合わせください。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default IsItPossibleToCreateAScratchCampaign
